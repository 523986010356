import * as React from 'react';
import courses1 from "../../images/course1.png"
import courses2 from "../../images/course2.png"
import courses3 from "../../images/course3.png"
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
//Translations
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CoursesInfo() {
  //Translations
  const [t, i18n] = useTranslation("global")

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return <>
    <p>{t("courses.ourCoursesDes")}</p>

    <div className="course-info">
      <h2 className="cour-title">{t("courses.islamicStudies")}</h2>
      <div className="cour-des">
        <img width="450" height="250" src={courses3} alt="Islamic Studies" />
        <div id="accordionFlushExample">
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>{t("courses.Aqidah")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">{t("courses.courselevel")}</Typography>
              <Typography><span className="bold">{t("courses.beginnerlevel")}</span>{t("courses.Aqidah1")}</Typography>
              <Typography><span className="bold">{t("courses.intermediatelevel")}</span>{t("courses.Aqidah2")}</Typography>
              <Typography><span className="bold">{t("courses.advancedlevel")}</span>{t("courses.Aqidah3")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>{t("courses.Seerah")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">
                {t("courses.courselevel")}
              </Typography>
              <Typography ><span className="bold">{t("courses.beginnerlevel")}</span>{t("courses.Seerah1")}</Typography>
              <Typography ><span className="bold">{t("courses.intermediatelevel")}</span>{t("courses.Seerah2")}</Typography>
              <Typography ><span className="bold">{t("courses.advancedlevel")}</span>{t("courses.Seerah3")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Typography>{t("courses.Tafseer")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">{t("courses.TafseerDes")}</Typography>
              <Typography>{t("courses.Tafseer1")}</Typography>
              <Typography>{t("courses.Tafseer2")}</Typography>
              <Typography>{t("courses.Tafseer3")}</Typography>
              <Typography>{t("courses.Tafseer4")}</Typography>
              <Typography>{t("courses.Tafseer5")}</Typography>
              <Typography>{t("courses.Tafseer6")}</Typography>
              <Typography>{t("courses.Tafseer7")}</Typography>
              <Typography>{t("courses.Tafseer8")}</Typography>
              <Typography>{t("courses.Tafseer9")}</Typography>
              <Typography>{t("courses.Tafseer10")}</Typography>
              <Typography>{t("courses.Tafseer11")}</Typography>
              <Typography>{t("courses.Tafseer12")}</Typography>
              <Typography>{t("courses.Tafseer13")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
              <Typography>{t("courses.Fiqh")}</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Typography className="bold">{t("courses.FiqhDes")}</Typography>
              <Typography><span className="bold">{t("courses.level1")}</span>{t("courses.Fiqh1")}</Typography>
              <Typography><span className="bold">{t("courses.level2")}</span>{t("courses.Fiqh2")}</Typography>
              <Typography><span className="bold">{t("courses.level3")}</span>{t("courses.Fiqh3")}</Typography>
              <Typography><span className="bold">{t("courses.level4")}</span>{t("courses.Fiqh4")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
              <Typography>{t("courses.Hadith")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">{t("courses.HadithDes")}</Typography>
              <Typography><span className="bold">{t("courses.beginnerlevel")}</span>{t("courses.Hadith1")}</Typography>
              <Typography><span className="bold">{t("courses.intermediatelevel")}</span>{t("courses.Hadith2")}</Typography>
              <Typography><span className="bold">{t("courses.advancedlevel")}</span>{t("courses.Hadith3")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
              <Typography>{t("courses.Manners")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t("courses.Manners1")}</Typography>
              <Typography>{t("courses.Manners2")}</Typography>
              <Typography>{t("courses.Manners3")}</Typography>
              <Typography>{t("courses.Manners4")}</Typography>
              <Typography>{t("courses.Manners5")}</Typography>
              <Typography>{t("courses.Manners6")}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <Link className="btn-shap free" to="https://calendly.com/salahamin911/30min" target="_blank"
      >{t("header.bookFree")}</Link>
    </div>
    <div className="course-info">
      <h2 className="cour-title">{t("courses.arabicStudies")}</h2>
      <div className="cour-des reverse">
        <img width="450" height="250" src={courses2} alt="Arabic Studies" />
        <div id="accordionFlushExample">
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
              <Typography>{t("courses.grammar")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">{t("courses.grammarDes")}</Typography>
              <Typography>{t("courses.grammar1")}</Typography>
              <Typography>{t("courses.grammar2")}</Typography>
              <Typography>{t("courses.grammar3")}</Typography>
              <Typography>{t("courses.grammar4")}</Typography>
              <Typography>{t("courses.grammar5")}</Typography>
              <Typography>{t("courses.grammar6")}</Typography>
              <Typography>{t("courses.grammar7")}</Typography>
              <Typography>{t("courses.grammar8")}</Typography>
              <Typography>{t("courses.grammar9")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
            <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
              <Typography>{t("courses.Conversation")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("courses.ConversationDes")}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
            <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
              <Typography>{t("courses.arabicChildren")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t("courses.arabicChildrenDes")}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
            <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
              <Typography>{t("courses.arabicQuran")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t("courses.arabicQuran1")}</Typography>
              <Typography>{t("courses.arabicQuran2")}</Typography>
              <Typography>{t("courses.arabicQuran3")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
            <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
              <Typography>{t("courses.arabicModern")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">{t("courses.arabicModernDes")}</Typography>
              <Typography>{t("courses.arabicModern1")}</Typography>
              <Typography>{t("courses.arabicModern2")}</Typography>
              <Typography>{t("courses.arabicModern3")}</Typography>
              <Typography className="bold">{t("courses.arabicModernDes2")}</Typography>
              <Typography>{t("courses.arabicModern4")}</Typography>
              <Typography>{t("courses.arabicModern5")}</Typography>
              <Typography>{t("courses.arabicModern6")}</Typography>
              <Typography>{t("courses.arabicModern7")}</Typography>
              <Typography>{t("courses.arabicModern8")}</Typography>
              <Typography className="bold">{t("courses.arabicModernDes3")}</Typography>
              <Typography>{t("courses.arabicModern9")}</Typography>
              <Typography>{t("courses.arabicModern10")}</Typography>
              <Typography>{t("courses.arabicModern11")}</Typography>
              <Typography>{t("courses.arabicModern12")}</Typography>
              <Typography>{t("courses.arabicModern13")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
            <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
              <Typography>{t("courses.alSarf")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t("courses.alSarf1")}</Typography>
              <Typography>{t("courses.alSarf2")}</Typography>
              <Typography>{t("courses.alSarf3")}</Typography>
              <Typography>{t("courses.alSarf4")}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
            <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
              <Typography>{t("courses.egyptianDialect")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t("courses.egyptianDialect1")}</Typography>
              <Typography>{t("courses.egyptianDialect2")}</Typography>
              <Typography>{t("courses.egyptianDialect3")}</Typography>
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
      <Link className="btn-shap free" to="https://calendly.com/salahamin911/30min" target="_blank"
      >{t("header.bookFree")}</Link>
    </div>
    <div className="course-info">
      <h2 className="cour-title">{t("courses.quran")}</h2>
      <div className="cour-des">
        <img width="450" height="250" src={courses1} alt="Qur’an" />
        <div id="accordionFlushExample">
          <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
            <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
              <Typography>{t("courses.quranRecitation")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="bold">{t("courses.quranRecitation1")}</Typography>
              <Typography>{t("courses.quranRecitation2")}</Typography>
              <Typography>{t("courses.quranRecitation3")}</Typography>
              <Typography>{t("courses.quranRecitation4")}</Typography>
              <Typography>{t("courses.quranRecitation5")}</Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
            <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
              <Typography>{t("courses.quranMemorizing")}</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Typography>{t("courses.quranMemorizing1")}</Typography>
              <Typography>{t("courses.quranMemorizing2")}</Typography>
              <Typography>{t("courses.quranMemorizing3")}</Typography>
              <Typography className="bold">{t("courses.quranMemorizing4")}</Typography>
              <Typography>{t("courses.quranMemorizing5")}</Typography>
              <Typography>{t("courses.quranMemorizing6")}</Typography>
              <Typography>{t("courses.quranMemorizing7")}</Typography>

            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
            <AccordionSummary aria-controls="panel17d-content" id="panel17d-header">
              <Typography>{t("courses.Tajweed")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t("courses.Tajweed1")}</Typography>
              <Typography>{t("courses.Tajweed2")}</Typography>
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
      <Link className="btn-shap free" to="https://calendly.com/salahamin911/30min" target="_blank">{t("header.bookFree")}</Link>
    </div>
  </>

}



