import React from 'react'
import planning from '../../images/planning.png'
import schedule from '../../images/schedule.png'
import studying from '../../images/studying.png'
//Translation
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

export default function HowWorks() {
  //Translation
  const [t] = useTranslation("global")
  return <>
    <section className="how-works">
      <div className="container">
        <h1>{t("howWork.hHowWork")}</h1>
        <div className="steps">
          <div className="step"> <img width="100" height="100" src={planning} alt="Planning" />
            <h4>{t("howWork.howWorkPlanNum")}</h4>
            <h3>{t("howWork.howWorkPlan")}</h3>
            <p>{t("howWork.howWorkPlanDes")}</p>
          </div>
          <div className="step"> <img width="100" height="100" src={schedule} alt="Scheduling" />
            <h4>{t("howWork.howWorkSchedNum")}</h4>
            <h3>{t("howWork.howWorkSched")}</h3>
            <p>{t("howWork.howWorkSchedDes")}</p>
          </div>
          <div className="step"> <img width="100" height="100" src={studying} alt="Studying" />
            <h4>{t("howWork.howWorkStudyNum")}</h4>
            <h3>{t("howWork.howWorkStudy")}</h3>
            <p>{t("howWork.howWorkStudyDes")}</p>
          </div>
        </div>
        <p className="simple">{t("howWork.howWorkSimple")}</p><Link className="btn-shap" to="https://calendly.com/salahamin911/30min"
          target="_blank">{t("howWork.howWorkStart")}</Link>
      </div>
    </section>

  </>
}

