import React, { useState } from "react";
import logoFav from "../../images/logofav.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
//Translations
import { useTranslation } from "react-i18next";

export default function Whatsapp() {
  //Translations
  const [t, i18n] = useTranslation("global");

  const [openWidget, setOpenWidget] = useState(false);
  const openWhatsappWidget = () => {
    setOpenWidget(!openWidget);
  };
  return (
    <>
      <div className={`whatsapp-Widget ${openWidget ? "show" : ""}`}>
        <CloseIcon onClick={() => openWhatsappWidget()} className="close" />
        <div className="top">
          <img src={logoFav} />
          <div className="info">
            <h3 data-i18n="iwanacademy">{t("landing.iwanAcadmy")}</h3>
            <p data-i18n="repliesWithin">{t("whatsApp.repliesWithin")}</p>
          </div>
        </div>
        <div className="chat">
          <div className="message">
            <h3 data-i18n="iwanacademy">{t("landing.iwanAcadmy")}</h3>
            <div className="message-info">
              <p data-i18n="asSalamu">{t("whatsApp.asSalamu")}</p>
              <p data-i18n="WhatsMessage">{t("whatsApp.WhatsMessage")}</p>
            </div>
          </div>
        </div>
        <a
          className="start-chat"
          href="https://wa.me/201040564352"
          data-number="201040564352"
          data-ga-label="Iwan Academy"
          target="_blank"
          data-i18n="WhatsChat"
        >
          {t("whatsApp.WhatsChat")}
        </a>
      </div>
      <button
        onClick={() => openWhatsappWidget()}
        className="wtsapp-icon"
        aria-label="WhatsApp"
      >
        <WhatsAppIcon className="whatsAppIcon" />
      </button>
    </>
  );
}
