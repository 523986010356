import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import HttpApi from "i18next-http-backend";

import global_en from "./translations/en/global.json";
import global_ar from "./translations/ar/global.json";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar"],
    resources: {
      en: {
        global: global_en,
      },
      ar: {
        global: global_ar,
      },
    },
    fallbackLng: "en",
    debug: false,
    lng: localStorage.getItem("lang") || "en",

    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "./translations/{{lng}}/global.json",
    },
  });

export default i18n;
