import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreateBlogs.css";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess, toastWarning } from "../../ToastAlerts";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../images/logofav.png";
import { RotatingLines } from "react-loader-spinner";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
//
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GoUp from "./../GoUp/GoUp";
import ThreeDots from "./../Loading/Loading";
import { Link, useNavigate } from "react-router-dom";
//Translation
import { useTranslation } from "react-i18next";

export default function CreateBolgs() {
  /* -------------------------------- set date -------------------------------- */
  const timeNow = new Date();
  const month = timeNow.getUTCMonth() + 1;
  const day = timeNow.getDate();
  const year = timeNow.getFullYear();
  const blogDate = `${day}-${month}-${year}`;
  /* -------------------------------- set date -------------------------------- */
  /* -------------------------------- Loading ------------------------------- */
  const [enLoading, setEnLoading] = useState(false);
  const [arLoading, setArLoading] = useState(false);
  const [load, setload] = useState(false);
  const [loading, setloading] = useState(false);
  /* -------------------------------- Loading ------------------------------- */
  /* ------------------------- Change Language Handle ------------------------- */
  const [bloglang, setBloglang] = React.useState("");
  const handleChange = (event) => {
    setBloglang(event.target.value);
  };
  /* ------------------------- Change Language Handle ------------------------- */
  /* ----------------- Quill modules and formats configuration ---------------- */
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];
  /* ----------------- Quill modules and formats configuration ---------------- */
  /* ------------------------------- Translation And Use Effect ------------------------------ */
  const [t, i18n] = useTranslation("global");
  //
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    // window.location.reload(false);
  };
  let localLang = localStorage.getItem("lang");
  useEffect(() => {
    document.title = `Iwan Academy | Dashboard`;
    document.documentElement.lang = i18n.language;
    //Call Api
    getBlogs();
  }, [localLang]);
  /* ------------------------------- Translation And Use Effect ------------------------------ */
  /* -------------------- Set Variables Use Hook useFormik -------------------- */
  let formik = useFormik({
    initialValues: {
      original_title: "",
      images: [null, null], // Array to hold two images
      title: "",
      overview: "",
      blog_content: "",
      date: blogDate,
    },
    onSubmit: registerSubmit,
  });
  /* -------------------- Set Variables Use Hook useFormik -------------------- */

  /* ------------------------------ Handle Formik Image ------------------------------ */
  const handleImageChange = (event, index) => {
    // Update the corresponding image in the array
    const newImages = [...formik.values.images];
    newImages[index] = event.currentTarget.files[0];
    formik.setFieldValue("images", newImages);
  };
  /* ------------------------------ Handle Formik Image ------------------------------ */

  ////////
  const [isEditing, setIsEditing] = useState(false);
  const [idUpdateBlog, setIdUpdateBlog] = useState(null);
  const [editBlog, setEditBlog] = useState({
    original_title: "",
    title: "",
    overview: "",
    blog_content: "",
  });
  ////////
  /* -------------------------------- Call Api -------------------------------- */
  const [Blogs, setBlogs] = useState([]);
  const getBlogs = async () => {
    setloading(true);
    let { data } = await axios.get(
      localLang === "en"
        ? `https://iwan-acadamy-backend.vercel.app/api/blogs/getenglishblogs`
        : `https://iwan-acadamy-backend.vercel.app/api/blogs/getarabicblogs`
    );
    setBlogs(data.blogs.reverse());
    setloading(false);
  };
  /* -------------------------------- Call Api -------------------------------- */
  /* ------------------------ Register Submit Function ------------------------ */
  async function registerSubmit(e) {
    if (!isEditing) {
      const { values } = formik;
      if (e.target.name === "en" || e.target.name === "ar") {
        if (
          !values.original_title ||
          !values.title ||
          !values.blog_content ||
          !values.overview
        ) {
          return toastWarning("please add all fields");
        }
      }
      const formData = new FormData();
      values.images.forEach((image) => {
        formData.append(`images`, image);
      });
      formData.append("original_title", values.original_title);
      formData.append("title", values.title);
      formData.append("overview", values.overview);
      formData.append("date", values.date);
      formData.append("blog_content", values.blog_content);

      if (e.target.name === "en") {
        setEnLoading(true);
        let { data } = await axios.post(
          "https://iwan-acadamy-backend.vercel.app/api/blogs/createenglishblog",
          formData
        );
        setEnLoading(false);
        if (data.message === "original_title is already exist") {
          toastError("Original Title is Aleardy Exist");
        }
        if (data.message === "please add all fields") {
          toastError("Please add all inputs");
        }
        if (data.message === "please add images") {
          toastError("Please add images");
        }
        if (data.message === "in-valid format") {
          toastError("Please add Another Image Format");
        }
        if (data.message === "success") {
          toastSuccess("Blog Is Posted");
        }
        getBlogs();
      }
      if (e.target.name === "ar") {
        setArLoading(true);
        let { data } = await axios.post(
          "https://iwan-acadamy-backend.vercel.app/api/blogs/createarabicblog",
          formData
        );
        setArLoading(false);

        if (data.message === "original_title is already exist") {
          toastError("Original Title is Aleardy Exist");
        }
        if (data.message === "please add all fields") {
          toastError("Please add all inputs");
        }
        if (data.message === "Please add images") {
          toastError("Please add images");
        }
        if (data.message === "in-valid format") {
          toastError("Please add Another Image Format");
        }
        if (data.message === "success") {
          toastSuccess("Blog Is Posted.");
        }
        getBlogs();
      }
      return;
    }
    if (isEditing) {
      setEnLoading(true);

      const { blog_content, overview, title } = formik.values;
      const res = await axios.put(
        "https://iwan-acadamy-backend.vercel.app/api/blogs/updateblog",
        {
          _id: idUpdateBlog,
          blog_content,
          overview,
          title,
        }
      );
      setIsEditing(false);
      setEnLoading(false);
      getBlogs();
      formik.values.blog_content = "";
      formik.values.overview = "";
      formik.values.title = "";
      if (res.data.message === "success") {
        toastSuccess("Blog Is Updated.");
      }
    }
  }
  /* ------------------------ Register Submit Function ------------------------ */
  /* ------------------------------- Start Update Blog ------------------------------ */
  const updateBlog = (blog) => {
    //scroll to top
    window.scrollTo(0, 0);
    //update
    setIsEditing(true);

    setIdUpdateBlog(blog._id);
    setEditBlog({
      title: blog.title,
      overview: blog.overview,
      blog_content: blog.blog_content,
    });
    formik.values.blog_content = blog.blog_content;
    formik.values.overview = blog.overview;
    formik.values.title = blog.title;
  };
  /* ------------------------------- End Update Blog ------------------------------ */

  /* ------------------------------- Start Delete Blog ------------------------------ */
  const deleteBlog = async (blog) => {
    setload(true);
    const res = await axios.delete(
      `https://iwan-acadamy-backend.vercel.app/api/blogs/${blog._id}`
    );
    setload(false);
    if (res.data.message === "success") {
      toastSuccess("Blog Is Deleted.");
    }
    getBlogs();
  };
  /* ------------------------------- End Delete Blog ------------------------------ */
  /* ------------------------------- Start Reset ------------------------------ */
  const resetInputs = () => {
    formik.values.original_title = "";
    formik.values.title = "";
    formik.values.overview = "";
    formik.values.blog_content = "";
  };
  /* ------------------------------- End Reset ------------------------------ */
  /* -------------------------------- Navigate -------------------------------- */
  const navigate = useNavigate();
  const goToBlog = (blog) => {
    navigate(`/blogs/${blog.original_title}`);
  };
  /* -------------------------------- Navigate -------------------------------- */
  /* --------------------------------- Logout --------------------------------- */
  function logout() {
    localStorage.removeItem("userToken");
    navigate("/login");
  }
  /* --------------------------------- Logout --------------------------------- */
  return (
    <>

      <div className="create-blogs container mt-4 ">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Link to='/'>
        <img
          className="d-block mx-auto "
          width="100px"
          src={logo}
          alt="iwan academy"
        />
        </Link>
  

        <div className="form-shadow p-4 mt-4" dir="ltr">
          <h1>Create New Bolg</h1>
          {/* Formik */}
          <form className="formik" onSubmit={formik.handleSubmit}>
            {/* Original Title */}
            {!isEditing ? (
              <>
                <label htmlFor="original_title">Original Title :</label>
                <input
                  value={formik.values.original_title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name="original_title"
                  id="original_title"
                  type="text"
                  className="form-control mb-2"
                />
              </>
            ) : null}
            {/* Images */}
            {!isEditing ? (
              <div className="row">
                <div className="col">
                  <label htmlFor="main_image">Main Image :</label>
                  <input
                    className="form-control mb-2"
                    id="main_image"
                    name="main_image"
                    type="file"
                    onChange={(event) => handleImageChange(event, 0)}
                  />
                </div>
                <div className="col">
                  <label htmlFor="poster_image">Poster Image :</label>
                  <input
                    className="form-control mb-2"
                    id="poster_image"
                    name="poster_image"
                    type="file"
                    onChange={(event) => handleImageChange(event, 1)}
                  />
                </div>
              </div>
            ) : null}
            {/* Title */}
            <label htmlFor="title">Title :</label>
            <input
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="title"
              id="title"
              type="text"
              className="form-control mb-2"
            />
            {/* Overview */}
            <label htmlFor="overview">Overview :</label>
            <textarea
              value={formik.values.overview}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="overview"
              id="overview"
              type="text"
              className="overview form-control mb-2"
            />
            {/* ReactQuill */}
            <label htmlFor="blog_content">Blog Content :</label>
            <ReactQuill
              className="react-quill"
              id="blog_content"
              name="blog_content"
              modules={quillModules}
              formats={quillFormats}
              value={formik.values.blog_content}
              onChange={(value) => formik.setFieldValue("blog_content", value)}
            />
            {/* Buttons */}
            <div className="create-blog-btns d-flex justify-content-between">
              <div className="btns mt-2">
                {enLoading ? (
                  <button type="submit" className="reset btn-shap ">
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="4"
                      animationDuration="0.75"
                      width="16"
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    disabled={(!formik.isValid && !formik.dirty) || arLoading}
                    className="reset btn-shap"
                    name="en"
                    type="submit"
                    onClick={(e) => registerSubmit(e)}
                  >
                    {!isEditing ? "Create English Bloge" : "Update Blog"}
                  </button>
                )}
              </div>

              {!isEditing ? (
                <div className="btns mt-2 ">
                  {arLoading ? (
                    <button className="reset btn-shap ">
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth="4"
                        animationDuration="0.75"
                        width="16"
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button
                      disabled={(!formik.isValid && !formik.dirty) || enLoading}
                      className="reset btn-shap"
                      name="ar"
                      type="submit"
                      onClick={(e) => registerSubmit(e)}
                    >
                      Create Arabic Bloge
                    </button>
                  )}
                </div>
              ) : null}

              {!isEditing ? (
                <div className="btns mt-2">
                  <button
                    onClick={() => resetInputs()}
                    className="reset reset-btn btn-shap"
                  >
                    Reset
                  </button>
                </div>
              ) : null}
            </div>
          </form>
        </div>
        <div className="display-blog-page mt-5 mb-5">
          <Box width={4} sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">lang</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={bloglang}
                label="Blog"
                onChange={handleChange}
              >
                <MenuItem onClick={() => handleChangeLanguage("en")} value={20}>
                  English
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage("ar")} value={10}>
                  الـعربيـه
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <h1 className="mt-5">{t("blogs.displayBlog")}</h1>
          {loading === true ? (
            <div className="xxx">
              <ThreeDots />
            </div>
          ) : (
            <div className="english-blog">
              {Blogs?.length > 0 ? (
                <div
                  className="display-blogs"
                  dir={localLang === "en" ? "ltr" : "rtl"}
                >
                  {Blogs?.map((blog) => (
                    <Card key={blog._id}>
                      <CardActionArea onClick={() => goToBlog(blog)}>
                        <CardMedia
                          component="img"
                          width="350"
                          height="auto"
                          image={blog.main_image.secure_url}
                          alt={blog.title}
                        />
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            dir={"ltr"}
                          >
                            {blog.original_title}
                          </Typography>
                          <Typography gutterBottom variant="p" component="div">
                            {blog.title.split(" ", 5).join(" ")}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions className="card-actions d-flex align-items-center justify-content-between">
                        <IconButton
                          onClick={() => updateBlog(blog)}
                          color="primary"
                          aria-label="Edit"
                          size="midum"
                        >
                          <EditRoundedIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteBlog(blog)}
                          color="error"
                          aria-label="delete"
                          size="midum"
                        >
                          {load ? (
                            <RotatingLines
                              strokeColor="red"
                              strokeWidth="4"
                              animationDuration="0.75"
                              width="16"
                              visible={true}
                            />
                          ) : (
                            <DeleteIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      </CardActions>
                    </Card>
                  ))}
                </div>
              ) : null}
            </div>
          )}
        </div>
        <span onClick={() => logout()} className="logout btn-shap reset">
          Logout
        </span>
      </div>

      <GoUp />
    </>
  );
}
