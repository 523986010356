import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//Translations
import { useTranslation } from 'react-i18next'


export default function FAQS() {
  //Translations
  const [t] = useTranslation("global")

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }
  return <>
    <section className="faqs">
      <h1>{t("faqs.faqs")}</h1>
      <div className="container">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqOne")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqOneDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqTwo")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqTwoDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqThree")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqThreeDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqFour")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqFourDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqFive")}

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqFiveDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqSix")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqSixDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className='faqs-1'
          >
            <Typography sx={{ flexShrink: 0 }}>
              {t("faqs.faqSeven")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {t("faqs.faqSevenDes")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <a className="btn-shap free" href="https://calendly.com/salahamin911/30min" target="_blank">{t("header.bookFree")}</a>
      </div>
    </section>
  </>
}
