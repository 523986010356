import React, { useEffect } from 'react'
import CoursesSection from './../Courses Section/CoursesSection';
import CoursesInfo from '../Courses Section/CoursesInfo';

//Translations
import { useTranslation } from 'react-i18next'

export default function Courses() {
  //Translations
  const [t, i18n] = useTranslation("global")
  //Page Title
  let localLang = localStorage.getItem("lang");

  useEffect(() => {
    document.title = t("pageTitle.courses");
    window.scrollTo(0, 0);
  }, [localLang]);

  return <>
    <section className="head">
      <div className="container">
        <h2 data-i18n="courses">{t("header.courses")}</h2>
      </div>
    </section>
    <section className="courses-page page-padding">
      <div className='container'>
        <CoursesInfo />
      </div>
    </section>
  </>
}
