import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Layout from "./Components/Layout/Layout.jsx";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Courses from "./Components/Courses/Courses";
import Pricing from "./Components/Pricing/Pricing";
import Contact from "./Components/Contact/Contact";
import Blogs from "./Components/Blogs/Blogs";
import Login from "./Components/Login/Login";
import CreateBolgs from "./Components/CreateBolgs/CreateBolgs";
import BlogInformaion from "./Components/Blog Informaion/BlogInformaion";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute.jsx";
import Notfound from "./Components/Notfound/Notfound";

let routers = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "/about/", element: <About /> },
      { path: "/courses/", element: <Courses /> },
      { path: "/pricing/", element: <Pricing /> },
      // { path: '/blogs/', element: <Blogs /> },
      // { path: "/blogs/:title", element: <BlogInformaion /> },
      // { path: "blog/1", element: <BlogInformaion /> },
      { path: "/contact/", element: <Contact /> },
      { path: "*", element: <Notfound /> },
    ],
  },
  // { path: "/login/", element: <Login /> },
  {
    path: "/dashboard/",
    element: (
      <ProtectedRoute>
        <CreateBolgs />
      </ProtectedRoute>
    ),
  },
]);

function App() {
  return <RouterProvider router={routers} />;
}

export default App;
