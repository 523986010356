import React, { useEffect } from "react";
import Landing from "../Landing/Landing.jsx";
import AboutSection from "../About Section/AboutSection.jsx";
import CoursesSection from "./../Courses Section/CoursesSection";
import WhyIwan from "./../Why Iwan/WhyIwan";
import HowWorks from "../How It Works/HowWorks.jsx";
import BlogsSection from "../Blogs Section/BlogsSection.jsx";
import FAQS from "../FAQS/FAQS.jsx";
//Translations
import { useTranslation } from "react-i18next";
import BlogsSectionEx from "./../Blogs Section/BlogsSectionEx";

export default function Home() {
  //Translations
  const [t, i18n] = useTranslation("global");
  //Page Title
  let localLang = localStorage.getItem("lang");

  useEffect(() => {
    document.title = t("pageTitle.home");
    window.scrollTo(0, 0);
  }, [localLang]);
  return (
    <>
      <Landing />
      <AboutSection />
      <CoursesSection />
      <WhyIwan />
      <HowWorks />
      {/* <BlogsSection /> */}
      <FAQS />
    </>
  );
}
