import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar.jsx'
import Footer from '../Footer/Footer.jsx'
import Whatsapp from '../WhatsappWidget/Whatsapp.jsx'
import GoUp from '../GoUp/GoUp.jsx'


export default function () {
  return <>
    <Navbar />
    <Outlet></Outlet>
    <Footer />
    <Whatsapp />
    <GoUp />
  </>
}
