import React from 'react'
import aboutImg from "../../images/about-iamge.jpg"
import { Link } from 'react-router-dom'
//Translations
import { useTranslation } from 'react-i18next'

export default function About() {
  const [t, i18n] = useTranslation("global")

  return <>
    <section className="about-section">
      <div className="container">
        <h1>{t("aboutSection.hAbout")}</h1>
        <div className="about-us">
          <img width="350" height="473" className="about-image" src={aboutImg} alt="about image" />
          <div className="about-des">
            <p>{t("aboutSection.aboutDes")}</p>
            <p>{t("aboutSection.ourTutors")}</p>
            <p>{t("aboutSection.ourTutorsDes")}</p>
            <Link className="blog-read" to="about/">{t("aboutSection.readMore")}</Link>
          </div>
        </div>
      </div>
    </section>

  </>
}
