import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

//Icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
//Translations
import { useTranslation } from "react-i18next";

export default function Contact() {
  //Translations
  const [t] = useTranslation("global");
  //Page Title
  let localLang = localStorage.getItem("lang");
  useEffect(() => {
    document.title = t("pageTitle.contact");
    window.scrollTo(0, 0);
  }, [localLang]);
  //Email js
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_eylyrqc",
        "template_s25pjyr",
        form.current,
        "_ve8dYb1KWtehF1CK"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("send");
        },
        (error) => {
          console.log(error.text);
          console.log("no send");
        }
      );
  };
  return (
    <>
      <section className="head">
        <div className="container">
          <h2>{t("header.contact")}</h2>
        </div>
      </section>

      <section className="contact">
        <div className="container" id="discount">
          <div className="get-touch">
            <div className="content">
              <h2>{t("contact.gitIn")}</h2>
              <div className="box">
                <div className="line">
                  <EmailIcon className="contactIcons" />
                  <Link className="info" to="mailto:iwanacademy@yahoo.com">
                    iwanacademy@yahoo.com
                  </Link>
                </div>
                <div className="line">
                  <FacebookIcon className="contactIcons" />
                  <Link
                    className="info"
                    to="https://www.facebook.com/Iwanacademy"
                    target="_blank"
                    data-i18n="iwanacademy"
                  >
                    {t("landing.iwanAcadmy")}{" "}
                  </Link>
                </div>
                <div className="line">
                  <WhatsAppIcon className="contactIcons" />
                  <div className="info">
                    <Link
                      className="info"
                      to="https://wa.me/201040564352"
                      data-number="201040564352"
                      data-ga-label="Iwan Academy"
                      target="_blank"
                    >
                      201040564352
                    </Link>
                  </div>
                </div>
              </div>
              <Link
                className="btn-shap"
                to="https://calendly.com/salahamin911/30min"
                target="_blank"
              >
                {t("header.bookFree")}
              </Link>
            </div>
          </div>
          {/* <div className="form">
            <h2>{t("contact.sendUs")}</h2>
            <div className="content">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  className="input"
                  name="from_name"
                  id="from_name"
                  type="text"
                  placeholder={t("contact.name")}
                  required
                />
                <input
                  className="input"
                  name="email_id"
                  id="email_id"
                  type="email"
                  placeholder={t("contact.email")}
                  required
                />
                <input
                  className="input"
                  name="phone_id"
                  id="phone_id"
                  type="text"
                  placeholder={t("contact.phone")}
                  required
                />
                <textarea
                  className="input"
                  name="message"
                  id="message"
                  placeholder={t("contact.message")}
                ></textarea>
                <button className="btn-shap" type="submit" id="button">
                  {t("contact.send")}
                </button>
                <p className="massage-accept">
                  Thanks for getting in touch. We'll get back to you soon.
                </p>
              </form>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
