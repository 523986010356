import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//Translations
import { useTranslation } from "react-i18next";

export default function Pricing() {
  //Translations
  const [t] = useTranslation("global");
  //Page Title
  let localLang = localStorage.getItem("lang");
  useEffect(() => {
    document.title = t("pageTitle.pricing");
    window.scrollTo(0, 0);
  }, [localLang]);
  const [firstPriceing, setFirstPriceing] = useState(true);
  const [secondPriceing, setSecondPriceing] = useState(false);

  const onChangeSecondClass = () => {
    setFirstPriceing(false);
    setSecondPriceing(true);
  };
  const onChangFirstClass = () => {
    setFirstPriceing(true);
    setSecondPriceing(false);
  };

  return (
    <>
      <section className="head">
        <div className="container">
          <h2>{t("header.pricing")}</h2>
        </div>
      </section>
      <section className="packageprice">
        <p className="tagLine container">{t("pricing.pricingDes")}</p>
        <div className="tabGroup">
          <ul className="tabs">
            <li
              className={`${firstPriceing ? "uk-active" : ""}`}
              onClick={() => onChangFirstClass()}
            >
              <p>
                {t("pricing.30")}
                <span>{t("pricing.minutes")}</span>
              </p>
            </li>
            <li
              className={`${secondPriceing ? "uk-active" : ""}`}
              onClick={() => onChangeSecondClass()}
            >
              <p>
                {t("pricing.60")}
                <span>{t("pricing.minutes")}</span>
              </p>
            </li>
          </ul>
          <ul className="uk-margin">
            <li className={`price-content ${firstPriceing ? "uk-active" : ""}`}>
              <div className="plans30">
                <ul>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.2Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">60</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.2Days30One")}</p>
                        <p>{t("pricing.2Days30Two")}</p>
                        <p>{t("pricing.Days30Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.2Days30Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.2Days30Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.3Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">90</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.3Days30One")}</p>
                        <p>{t("pricing.3Days30Two")}</p>
                        <p>{t("pricing.Days30Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.3Days30Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.3Days30Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.4Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">120</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.4Days30One")}</p>
                        <p>{t("pricing.4Days30Two")}</p>
                        <p>{t("pricing.Days30Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.4Days30Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.4Days30Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.5Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">150</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.5Days30One")}</p>
                        <p>{t("pricing.5Days30Two")}</p>
                        <p>{t("pricing.Days30Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.5Days30Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.5Days30Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.6Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">180</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.6Days30One")}</p>
                        <p>{t("pricing.6Days30Two")}</p>
                        <p>{t("pricing.Days30Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.6Days30Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.6Days30Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`price-content ${secondPriceing ? "uk-active" : ""}`}
            >
              <div className="plans30 plans60">
                <ul>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.1Day")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">60</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.1Day60One")}</p>
                        <p>{t("pricing.1Day60Two")}</p>
                        <p>{t("pricing.Days60Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.1Day60Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.1Day60Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.2Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">120</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.2Days60One")}</p>
                        <p>{t("pricing.2Days60Two")}</p>
                        <p>{t("pricing.Days60Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.2Days60Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.2Days60Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.3Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">180</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.3Days60One")}</p>
                        <p>{t("pricing.3Days60Two")}</p>
                        <p>{t("pricing.Days60Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.3Days60Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.3Days60Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.4Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">240</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.4Days60One")}</p>
                        <p>{t("pricing.4Days60Two")}</p>
                        <p>{t("pricing.Days60Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.4Days60Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.4Days60Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.5Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {t("pricing.us")}
                          <span className="num">300</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.5Days60One")}</p>
                        <p>{t("pricing.5Days60Two")}</p>
                        <p>{t("pricing.Days60Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.5Days60Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.5Days60Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="planCard">
                      <div className="planwk">
                        <p>
                          <span>{t("pricing.6Days")}</span>
                          {t("pricing.weekly")}
                        </p>
                      </div>
                      <div className="plprice">
                        <h2>
                          {" "}
                          {t("pricing.us")}
                          <span className="num">360</span>{" "}
                          <sup>{t("pricing.month")}</sup>
                        </h2>
                      </div>
                      <div className="plspec">
                        <p>{t("pricing.6Days60One")}</p>
                        <p>{t("pricing.6Days60Two")}</p>
                        <p>{t("pricing.Days60Mins")}</p>
                      </div>
                      <div className="plcal">
                        <h6>
                          {t("pricing.6Months")}<span>{t("pricing.6Days60Save7")}</span>
                          <span className="rd">{t("pricing.save7")}</span>
                        </h6>
                        <h6>
                          {t("pricing.12Months")}<span>{t("pricing.6Days60Save15")}</span>
                          <span className="rd">{t("pricing.save15")}</span>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <Link className="btn-shap free" to="https://calendly.com/salahamin911/30min" target="_blank">{t("header.bookFree")}</Link>
      </section>
    </>
  );
}
