import React, { useEffect } from 'react'
import quran from '../../images/quran.png'
import aboutImg from '../../images/about-iamge.jpg'
//Translations
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";


export default function About() {
  //Translations
  const [t] = useTranslation("global")
  //Page Title
  let localLang = localStorage.getItem("lang");

  useEffect(() => {
    document.title = t("pageTitle.about");
    window.scrollTo(0, 0);
  }, [localLang]);
  return <>
    <Helmet>
      <meta name="description"
        content="Iwan Academy is an educational online academy in order to serve online Qur’an, Tajweed, Arabic Language, and Islamic Studies to Arabs & non-Arabic speakers." />
      <title>{t("pageTitle.about")}</title>
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
    <section className="head">
      <div className="container">
        <h2>{t("header.aboutUs")}</h2>
      </div>
    </section>
    <section className="about">
      <div className="container">
        {/* <!-- Mission --> */}
        <div className="about-mission">
          <p className="word">{t("aboutPage.aboutPage1")}</p>
          <div className="discription">
            <p>{t("aboutPage.aboutPage2")}</p>
            <p>{t("aboutPage.aboutPage3")}</p>
            <p>{t("aboutPage.aboutPage4")}</p>
            <p>{t("aboutPage.aboutPage5")}</p>
          </div>
        </div>
        {/* <!-- Mission --> */}
        <img className="quran" src={quran} alt="quran" />
        {/* <!-- about iwan --> */}
        <div className="about-us">
          <img className="about-image" src={aboutImg} alt="about image" />
          <div className="about-des">
            <p>{t("aboutSection.aboutDes")}</p>
            <p>{t("aboutSection.ourTutors")}</p>
            <p>{t("aboutSection.ourTutorsDes")}</p>
          </div>
        </div>
        {/* <!-- about iwan --> */}
        <a className="btn-shap free" href="https://calendly.com/salahamin911/30min">{t("header.bookFree")}</a>
      </div>
    </section>
  </>
}
