import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'



export default function Landing() {
  const [t, i18n] = useTranslation("global")
  return <>
    <section className="landing">
      <div className="container">
        <div className="text">
          <p>{t("landing.welcome")}</p>
          <h1>{t("landing.iwanAcadmy")}</h1>
          <p>{t("landing.learn")}</p>
          <div className="btns"> <Link className="change-btn btn-shap" to="courses/">{t("header.courses")}</Link>
            <Link className="btn-shap"
              to="contact/">{t("header.contact")}</Link>
          </div>
        </div>
      </div>
    </section>
  </>
}
