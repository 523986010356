import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import { Link, NavLink } from "react-router-dom";
//Translation
import { useTranslation } from "react-i18next";
//

export default function Navbar() {
  //Translation
  const [t, i18n] = useTranslation("global");
  //
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };
  //
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
    document.documentElement.lang = i18n.language;
    localStorage.setItem("lang", i18n.language);
  }, [i18n, i18n.language]);
  const dir = i18n.dir(i18n.language);
  //
  const [showNav, setShowNav] = useState(false);
  const openNav = () => {
    setShowNav(!showNav);
  };
  return (
    <>
      <header className="header">
        <div className="container">
          <NavLink to="" name="logo">
            <img
              width="120"
              height="60"
              className="logo"
              src={logo}
              alt="logo"
            />
          </NavLink>
          <nav className={`nav-bar ${showNav ? "show" : ""}`} dir={dir}>
            <ul className="nav-links">
              <li>
                <NavLink onClick={() => openNav()} to="/">
                  {t("header.home")}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => openNav()} to="/about/">
                  {t("header.aboutUs")}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => openNav()} to="/courses/">
                  {t("header.courses")}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => openNav()} to="/pricing/">
                  {t("header.pricing")}
                </NavLink>
              </li>
              {/* <li>
                <NavLink onClick={() => openNav()} to="/blogs/">
                  {t("header.blogs")}
                </NavLink>
              </li> */}
              <li>
                <NavLink onClick={() => openNav()} to="/contact/">
                  {t("header.contact")}
                </NavLink>
              </li>
            </ul>
          </nav>
          {i18n.language === "ar" && (
            <button className=" lg" onClick={() => handleChangeLanguage("en")}>
              English
            </button>
          )}
          {i18n.language === "en" && (
            <button className=" lg" onClick={() => handleChangeLanguage("ar")}>
              الـعربيـة
            </button>
          )}
          <div
            onClick={() => openNav()}
            className={`navTrigger ${showNav ? "active" : ""}`}
          >
            <i></i>
            <i></i>
            <i></i>
          </div>
          <Link
            className="header-book header-book-home btn-shap"
            to="https://calendly.com/salahamin911/30min"
            target="_blank"
          >
            {t("header.bookFree")}
          </Link>
        </div>
      </header>
    </>
  );
}
