import * as React from 'react';
import CoursesInfo from './CoursesInfo';
//Translations
import { useTranslation } from 'react-i18next'


export default function CoursesSection() {
  //Translations
  const [t, i18n] = useTranslation("global")


  return <>
    <section className="courses-page section-padding">
      <div className="container">
        <h1>{t("header.courses")}</h1>
        <CoursesInfo />
      </div>
    </section>
  </>
}



