import React from 'react'
import videocall from "../../images/videocall.png"
import islamic from "../../images/islamic.png"
import learning from "../../images/learning.png"
import workingHours from "../../images/working-hours.png"
import free from "../../images/free.png"
//Translation
import { useTranslation } from 'react-i18next'

export default function WhyIwan() {
  //Translation
  const [t, i18n] = useTranslation("global")
  return <>
    <section className="why-iwan">
      <div className="container">
        <h1>{t("whyIwan.hWhy")}</h1>
        <div className="features">
          <div className="box"> <img src={videocall} alt="One-on-One" />
            <h3>{t("whyIwan.whyOne")}</h3>
            <p>{t("whyIwan.whyOneDes")}</p>
          </div>
          <div className="box"> <img src={islamic} alt="Professional Tutors" />
            <h3>{t("whyIwan.whyPro")}</h3>
            <p>{t("whyIwan.whyProDes")}</p>
          </div>
          <div className="box"> <img src={learning} alt="Learn With Fun" />
            <h3>{t("whyIwan.whyFun")}</h3>
            <p>{t("whyIwan.whyFunDes")}</p>
          </div>
          <div className="box"> <img src={workingHours} alt="Flexible Schedule" />
            <h3>{t("whyIwan.whyFlex")}</h3>
            <p>{t("whyIwan.whyFlexDes")}</p>
          </div>
          <div className="box"> <img src={free} alt="Free Demo Class" />
            <h3>{t("whyIwan.whyFree")}</h3>
            <p>{t("whyIwan.whyFreeDes")}</p>
          </div>
        </div>
      </div>
    </section>
  </>

}
