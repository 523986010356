import React from "react";
import { useTranslation } from "react-i18next";

export default function Notfound() {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="not-found py-5 my-5 container">
        <h2>{t("notFound.notFound")}</h2>
        <p>{t("notFound.notFoundDes")}</p>
      </div>
    </>
  );
}
