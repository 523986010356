import React from "react";
import { Link } from "react-router-dom";
//Icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
//Translations
import { useTranslation } from "react-i18next";

export default function Footer() {
  //Translations
  const [t] = useTranslation("global");

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="box">
            <h4 data-i18n="about">{t("header.aboutUs")}</h4>
            <p className="text" data-i18n="aboutMin">
              {t("footer.aboutMin")}
            </p>
          </div>
          <div className="box">
            <h4 data-i18n="iwanacademy">{t("landing.iwanAcadmy")}</h4>
            <ul className="links">
              <li>
                <Link to="" data-i18n="home">
                  {t("header.home")}
                </Link>
              </li>
              <li>
                <Link to="about/" data-i18n="about">
                  {t("header.aboutUs")}
                </Link>
              </li>
              <li>
                <Link to="courses/" data-i18n="courses">
                  {t("header.courses")}
                </Link>
              </li>
              <li>
                <Link to="pricing/" data-i18n="pricing">
                  {t("header.pricing")}
                </Link>
              </li>
              {/* <li><Link to="blogs/" data-i18n="Blogs">{t("header.blogs")}</Link></li> */}
              <li>
                <Link to="contact/" data-i18n="contact">
                  {t("header.contact")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="box">
            <h4 data-i18n="getTouch">{t("footer.getTouch")}</h4>
            <div className="line">
              <EmailIcon className="contactIcons" />
              <Link className="info" to="mailto:iwanacademy@yahoo.com">
                iwanacademy@yahoo.com
              </Link>
            </div>
            <div className="line">
              <FacebookIcon className="contactIcons" />
              <Link
                className="info"
                to="https://www.facebook.com/Iwanacademy"
                target="_blank"
              >
                {t("landing.iwanAcadmy")}
              </Link>
            </div>
            <div className="line">
              <WhatsAppIcon className="contactIcons" />
              <div className="info">
                <Link
                  className="info"
                  to="https://wa.me/201040564352"
                  target="_blank"
                >
                  201040564352
                </Link>
              </div>
            </div>
          </div>
        </div>
        <p className="container copyright" data-i18n="copyRight">
          {t("footer.copyRight")}
        </p>
      </footer>
    </>
  );
}
